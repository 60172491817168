h4.sidebar-title {
	text-transform: uppercase;
}
ul#news-list {

	margin:0;

	li {
		list-style: none;
		border-bottom: 1px dotted #ccc;
		padding:20px 0;
		line-height: 1.2;
	}
}

