/*------ HEADER ------------------------------------------ */

.wf-loading nav.desktop,
.wf-loading #lead p,
.wf-loading #cd-timeline h4,
.wf-loading #secondary h3 { visibility: hidden; }

.wf-active nav.desktop,
.wf-active #lead p,
.wf-active #cd-timeline h4,
.wf-active #secondary h3 { visibility: visible; }



header {
}


/*------ LOGO ------------------------------------------ */

#logo img {
	width: 150px;
	margin: 15px 0 15px 15px;
}



/*------ NAV ------------------------------------------ */

nav {

	a {
		text-transform: uppercase;
		font-size: 21px;
		@media screen and (min-width: 768px) and (max-width: 900px) {
			font-size: 16px;
		}
		font-weight: 400;
		font-family: $heading-font;
	}

	a i { font-size: 21px!important; }

	.search-container {
		display: inline-block;
		position: relative;
	}

	.display-search {
		cursor: pointer;
		vertical-align: top;
	}

	.search-form {
		position: absolute;
		top: 100%;
		right: 0;
		width: 200px;
	}

}

/*------ DESKTOP ------------------------------------------ */


nav.desktop {

	float:right;
	min-width: 560px;
	padding-top: 18px!important;

	li.top {
		display: inline;
		float: left!important;
		list-style: none;
	}

	a {
		color:$brown;
		padding:0 21px !important;
		@media screen and (min-width: 768px) and (max-width: 921px) {
			padding: 2px 16px !important;
		}
	}

	a:hover {
		color:$teal;
	}

	li ul {
		display: none;
		background-color: rgba(24,142,126,0.75);
		padding:24px 12px!important;
		width:200px;
		top: 38px!important;

		li a {
			color:#fff;
			font-size: 18px;
		}

		li a:hover {
			color:$brown;
		}
	}
	@media only screen and (min-width: 886px) {
		min-width: 632px;
	}
}


/*------ MOBILE ------------------------------------------ */


nav.pushy {

	background:$teal;
	// visibility: visible
	@media only screen and (min-width: 769px) {
		visibility: hidden !important;
	}
	ul {
		background: $teal;
	}
	li {
		list-style: none;
		line-height: 1.1;
		padding-left: 15px;
	}

	a {
		font-weight: 300;
	}
}

#mobile-search-form {
	padding:24px 10px;
	background: $grey;

	input {
		border:none;
		border-radius: 0px !important;
		height:2.5rem;
		margin:0;
	}

	.button {
		height:2.5rem;
		font-weight: 900;
		font-family: $sans-font;
		margin:0;
	}
}

.pushy ul:first-child {
	margin:0;
	padding:36px 0;
}