body.page-id-20 {
  .medium-9.medium-centered.columns {
    overflow: hidden;
  }
  #breadcrumbs {} 
  h2 {
    text-align: center;
  }
}




/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 99%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */

#cd-timeline {
  position: relative;
}

.cd-timeline-block {
  position: relative;
  margin-bottom: 100px;

  @include breakpoint (medium) {
    margin-bottom: 100px;

      img {
      margin-bottom: 100px
    }
  }


}
.cd-timeline-block::after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-circle {
  @include breakpoint(medium) {
    position: absolute;
  }
  background: $teal;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  padding-top:14px;
  border-radius: 50%;
  text-align: center;
  font-weight:bold;
  font-size: 21px;
  letter-spacing: -1px;
  color:#fff;
}

#cd-timeline h4 {
  font-size: 30px;
  color:$teal;
  @include breakpoint(medium) {
    font-size: 42px;
    padding-top: 6px;
  }
  line-height: 1;
  letter-spacing: -1px;

  span {
    font-weight: 700;
    font-size: 18px;
    color:$grey;
    letter-spacing: normal;
    margin:0;
    padding:0;
  }
}

#cd-timeline p {
  @include breakpoint(medium) {
    font-size: 18px;
  }
}

@include breakpoint(medium) {

  #cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 2px;
    background: #d7e4ed;
  }

  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
    padding: 2em 0;
    
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }

  .timeline-circle {
    left: 50%;
    margin-left: -30px;
    }

    .cd-timeline.left {
      width:45%;
      float: left;

      p, h4 {
        text-align: right;
      }

      img { float: right!important;}
    }

    .cd-timeline.right {
      width:45%;
      float: right;

      p, h4 {
        text-align: left;
      }

    }

}



