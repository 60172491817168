#page-intro {
	
	padding:0;
	margin-top: -60px;
	background-position: top center!important;
	background-size: cover!important;
	background-repeat: no-repeat!important;
	padding-top: 300px;
	@include breakpoint(medium) {
		padding-top: 600px;
	}
	
	

	h2, h3 { 
		color:#fff;
		font-weight: 400;
	}

	img {
		margin-top: -60px;
		width: 100%;
	}


	#intro-message {

		padding:0;

		h2 {
			background:rgba(88,56,43,0.75);
			padding:21px;
			@include breakpoint(medium) {
				padding:36px;
			}
			font-size: 24px;
			margin:0;
			line-height: 1.2;
			font-weight: 400;

			@include breakpoint(medium) {
				font-size: 30px;
			}
		}
	}

}


/* ---------  HIDE PAGE INTROS ON HOME PAGE ------------------------ */

.page-id-146 #page-intro-empty,  .page-id-146 #page-intro, body.home #page-intro {
	display: none;
}
/* ----------------------------------------------------------------- */

body.page-id-13 /* V&V */ #page-intro, body.page-id-201 /* Privacy */ #page-intro, body.page-id-13 /* V&V */ #page-intro, body.page-id-26 /* Responsibility */ #page-intro {
	background-position: top left!important;
}

body.page-id-316 /* Loose Leaf */ #page-intro{
	background-position: top right!important;
}
	

body.page-id-403 /* R&D */ #page-intro {
	background-position: bottom center!important;
}


body.page-id-8 /* About */ #page-intro, body.page-id-572 /* Other */ #page-intro {
	@include breakpoint(1300px) {
		background-position: 0px -100px!important;
	}
}
body.page-id-20 /* Heritage */ #page-intro, body.page-id-45 /* Heritage */ #page-intro {
	@include breakpoint(1300px) {
		background-position: 0px -200px!important;
	}
}
body.page-id-15 /* leadership */ #page-intro {
	background-position: bottom center!important;
}

body.page-id-69 /* becoming a supplier */ #page-intro, body.page-id-55 /* sustainability */ #page-intro {
	background-position: center center!important;
}

body.page-id-55 /* sustainability */ #page-intro {
	@include breakpoint(1600px) {
		background-position: top center!important;
	}
}

