body {
	font-family: $sans-font;
	background: #fff;
}

a {
	&:active, &:hover {
		outline: 0px solid transparent;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	font-weight: 400;
}

h2 {
	text-transform: uppercase;
	margin-bottom:6px;
	font-size: 36px;

	@include breakpoint(medium) {
		
		margin-top:48px;
	}
}

h2.page-title {
	
	font-family: $sans-font;
	font-weight: 700;
	font-size: 14px;
	padding-bottom: 18px;
	margin-top: 0;
}

h3 {
	font-size: 30px;
	margin-top: 30px;
	line-height: 1;
	color:teal;

}

li { list-style: square; }

input[type="search"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.button:not([type="submit"]) {
		border: none;
		font-family: inherit;
		font-size: inherit;
		color: inherit;
		background: none;
		cursor: pointer;
		padding: 25px 50px;
		display: inline-block;
		margin: 15px 30px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		outline: none;
		position: relative;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
		z-index: 4000;
	}

.button:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

