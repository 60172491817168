#comments {

	padding:100px 0;


	p.comment-notes, 
	h3#reply-title, 
	.comment-form-email, 
	p.comment-form-url,
	.says {
		display: none;
	}

	ol.comment-list {

		margin:0;

		li {
			font-size: 11px;
			list-style: none;
			padding:24px 0;
			border-top: 1px dotted #ccc;
		}
	}

	p {
		margin-top:12px;
		font-size: 24px;
	}

	cite {
		font-size: 16px;
	}

	cite:before {
	    content: '';
	}
	
}


footer {
	padding: 35px 0 50px 0;
	background:#8fa656;
	margin-top: 48px;

	nav {

		a {
			margin:0;
			text-transform: uppercase;
			font-family: $sans-font;
			font-weight: 400;
			font-size: 11px;
			display: block;
			line-height: 1.2;
			margin-bottom: 3px;
			color:$green;
		}

		a:first-child {
			font-weight: 700;
		}
	}

}

nav.footer {
	a:first-child {
		font-weight: 400;
	}
	@include breakpoint(medium down) {
		a {
			padding:8px 0!important;
		}
	}
}

a.copyright {
	font-size:12px;
	color:$green;	
}