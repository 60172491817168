/*------ WELCOME ------------------------------------------ */

body.page-id-305 {

	background:$brown;
	background: url("/wp-content/themes/asc/static/dist/assets/img/background-welcome.png") no-repeat top center;

	@include breakpoint(medium) {
		background-size: cover;
	}

	header, footer, #breadcrumbs {
		display: none;
	}

	#comments { display: none; }
}

a#welcome h1 {
	margin:64px 0;
}

#welcome-content {
	p {
		font-size: 14px;
		color:#fafafa;
		font-weight: 700;
	}

	.button {
		border: 3px solid #fafafa;
		color: #fafafa;
		background: none;
		margin-bottom: 48px;
	}

	.button:hover,
	.button:active {
		color: #fafafa;
		background: rgba(88,56,43,.75);
	}
}




/*------ HOME ------------------------------------------ */


body.page-id-146 #breadcrumbs {
	display: none;
} 

#intro {
	// margin-top: -61px; have to remove to accomodate firefox
	background: url("/wp-content/themes/asc/static/dist/assets/img/video-fallback-image.jpg") no-repeat;
	background-size: cover;
	overflow: hidden;
    position: relative;
	padding-top: 5%;
	top: -61px;
    z-index: -1;

	#bg-video {
		$videoSize: 1600px;
		display: none;
		position: absolute;
		top: 0;
		left: calc((100vw - #{$videoSize}) / 4);
		width: calc(100% + 50vh);
		max-width: $videoSize;
		/*max-height: 750px;*/
		z-index: -1;
		@media only screen and (min-width: #{$videoSize}) {
			left: calc((100vw - #{$videoSize}) / 2);
		}
	}
	@media only screen and (min-width: 800px) {
		&:not(.mobile-bg) {
			background: transparent;
			position: relative;
			overflow: hidden;
			width: 100%;
		}
		#bg-video {
			display: block;
		}
	}
	&.mobile-bg {
		// background: url("/wp-content/themes/asc/static/dist/assets/img/video-fallback-image.jpg") no-repeat !important;
		// background-size: cover;
		position: relative;
		z-index: -1;
	}
	#intro-message {
		padding:96px 0 0 0;
		z-index: 1;

		.brown-box {

			background:rgba(88,56,43,0.50);
			padding:24px;

			@include breakpoint(medium) {
				padding:48px;
			}

		}

		h2 {
			margin:0;
			line-height: 1.1;
			font-size: 30px;

			@include breakpoint(medium) {
				font-size: 68px!important;
				line-height: 1;
			}
		}

		a.button {
			margin:0 0 18px 0;
			padding:18px!important;
			background:rgba(24,142,126,0.75);
			font-family: $heading-font;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 18px;
			color:#fff;


		}

		h2, h3 { 
			color:#fff;
			font-weight: 700;
		}

	}


	@include breakpoint(medium) {

		a.button {
			width:400px;
		}

		#intro-message {
			padding:60px 0 415px 0;
		}


	}

	@include breakpoint(large) {

		a.button {
			font-size: 21px!important;
		}


	}
	

}

#content {
	/* RESPONSIBILITY BACKGROUND IMAGES */

	#sustain {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-sustain.jpg") no-repeat;
	}

	#philanthropy {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-philanthropy.jpg") no-repeat;
	}

	#responsible {
		background: url("http://placehold.it/580x385") no-repeat;
	}

	#tradition, #innovation, #commitment, #sustain, #philanthropy, #responsible {
		background-size: cover;
		position:relative;
		min-height: 200px;
		padding:0 12px;
	}
}

body.page-id-146 #secondary {
	margin: -61px 0 0 0!important;

	@include breakpoint(medium) {
		margin-top: -125px!important;
	}
}

#secondary {

	margin-top:0;
	margin-bottom: 100px;
	overflow: hidden;

	&.no-spacing {
		margin: 0 auto;
	}

	@media only screen and (min-width: 1024px) {
		.columns:first-child {
			padding-left: 2rem;
		}
		.columns:last-child {
			padding-right: 2rem;
		}
	}

	@include breakpoint(medium) {

		margin-top:36px;

		.row { 
			background: #fff; 
		}
		
	}

	/* HOME PAGE BACKGROUND IMAGES */

	#tradition {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-tradition.jpg") no-repeat;
	}
	#innovation {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-employees.jpg") no-repeat;
	}
	#commitment {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-commitment.jpg") no-repeat;
	}

	/* RESPONSIBILITY BACKGROUND IMAGES */

	#sustain {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-sustain.jpg") no-repeat;
	}

	#philanthropy {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-philanthropy.jpg") no-repeat;
	}

	#responsible {
		background: url("/wp-content/themes/asc/static/dist/assets/img/background-sub-rm.jpg") no-repeat;
	}

	#tradition, #innovation, #commitment, #sustain, #philanthropy, #responsible {
		background-size: 100%;
		position:relative;
		min-height: 200px;
		padding:0 12px;
	}
	h3 {
		padding: 165px 0px 0px;
		@include breakpoint(medium) {
			padding: 155px 0px 0px;
		}
	}
	h3 span {
		color:#fff;
		display: block;
		position: relative;//absolute;
		bottom:15px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: -1px;
		line-height: .75;
		font-size: 48px;
		@include breakpoint(medium) {
			font-size: 60px;
		}
	}

	p {
		margin-top:12px;
		font-size: 16px;
	}

	a {
		font-weight: 700;
	}
	
}

body.page-id-13 /* V&V */ #secondary {
	margin:0!important;
}


img.can {
		border-radius: 400px;
		margin-bottom: 12px;
	}


#brands {

	margin-top: 48px!important;

	h3 {
		text-align: center;
		padding-bottom: 24px;
		font-size: 24px;
		color:rgba(88,56,43,1);
	}

}

// Contact

#content .accordion-title h4 {
	padding-left: 1em;
}