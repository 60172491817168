/*------ ALL PAGES ------------------------------------------ */
img.alignright { 
	float: none;
    margin: 0 auto;
    padding: 24px 0;
    display: block;
	@media only screen and (min-width: 475px) {
		float:right;
		padding:0 0 24px 24px;
	}
}

@include breakpoint(medium) {
	p, li {
		font-size: 19px;
	}

}

#content {
	@include breakpoint(medium) {
		padding:36px 0;
	}
}
#main-content {
	margin-bottom: 30px;
}

#lead {

	font-size: 12px;
	margin: 24px 0;

	p {
		font-size: 30px;
		padding:0!important;
		line-height: 1.2;
		font-family: $heading-font;
		color:$green;

		@include breakpoint(medium) {
			font-size: 42px;
		}
	}
}

body.single #lead {

	p {
		font-size: 42px;
		line-height: 1;
		font-family: $heading-font;
		color:$green;
		
	}

}

#quote {

	margin-top: 48px;
	background: rgba(24,142,126,.2);
	position: relative;
	padding:21px;

	@include breakpoint(medium) {
		padding:36px;
	}
	
	p {
		color:$teal;
		font-family: $heading-font;
		font-size:24px;
		line-height: 1.2!important;

		@include breakpoint(medium) {
			font-size:30px;
		}
	}
}

#quote:after {
	top: 100%;
	left: 80%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-top-color: rgba(24,142,126,.2);
	border-width: 30px;
	margin-left: -30px;

	@include breakpoint(medium) {
		left: 90%;
	}

	@include breakpoint(large) {
		left: 95%;
	}
}

#quoter {
	font-size: 18px;
	line-height: 1.3;
	margin-top: 48px;
	text-align: right;
}


/*------ BREADCRUMBS ------------------------------------------ */
#breadcrumbs {
	background: $teal;
	padding:7px 0;
	font-size: 14px;
	color:#fff;
	font-weight: 900;
	text-transform: uppercase;
	
	a {
		color:#fff;
	}

}

body.search #breadcrumbs {
	display: none;
}

/*------ HERITAGE PAGE ------------------------------------------ */

/*body.page-id-20 #main-content img {
	margin: 40px 0 10px 0;
}*/

/*------ FACILITIES ------------------------------------------ */

#facilities {
	margin-top:24px;
	margin-bottom: 12px;

	@include breakpoint(small down) {
		img {
			margin-bottom: 12px;
		}
	}

	img { 
		padding-top: 12px;
	}
}


/*------ LEADERSHIP ------------------------------------------ */

#leadership {

	padding:12px 0;
	line-height:1.3;

	.column {
		margin-bottom: 24px;
	}	

	strong { 
		font-weight: 700; 
	}

}


/*------ CONTACT ------------------------------------------ */

ul.accordion { 
	border:none;
	li {
		list-style: none;
	}
}


/*------ NEWS ------------------------------------------ */

#news-page {
	margin:0;
}
.news-page-list {
	
	margin:0!important;
	
	li { 
		padding:20px 0;
		list-style: none;
		border-bottom: 1px dotted #ccc;
	}

	h3 {
		margin-top: 0!important;
		line-height: 1.2;
		font-weight:400;
	}
}

body.page-id-36 #sidebar p {
	font-size: 14px;

}

#media-relations {
	margin-top: 48px;
	p {
		font-size: 16px!important;
	}
}


/*------ RESPONSIBILITY ------------------------------------------ */

#responsibility {

	margin-top:36px;
	margin-bottom: 100px;

	@include breakpoint(medium) {
		margin-top: -50px;

		.row { 
			background: #fff; 
		}

		.columns:first-child {
			padding-left: 2rem;
		}

		.columns:last-child {
			padding-right: 2rem;
		}
	}

	h3 a {
		color:#fff;
		position: absolute;
		bottom:0;
		font-size: 60px;
		text-transform: uppercase;
		padding:0!important;
		font-weight: 700;
		letter-spacing: -1px;
		line-height: .9;
	}

	p {
		margin-top:12px;
		line-height: 1.2;
		font-size: 14px;
	}
	
}


/*------ BRANDS ------------------------------------------ */

#brand-product-list {
	h3 {
		font-size: 29px;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
	}
	img {
		display: block;
		margin: 0 auto;
	}
	@media only screen and (min-width: 768px) {
		h3 {
			text-align: left;
		}
		img {
			display: inherit;
		}
	}
}
	
hr {
	margin: 60px 0!important;
	border-bottom: 1px dotted #cacaca!important;
}
	

hr:last-child {
		display: none!important;
	}


img.brand-image {
	width:175px;
}

.brand p {
	@include breakpoint(medium) {
		font-size: 14px;
		line-height: 1.5;
	}
}






